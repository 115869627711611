
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'List'
})
export default class List extends Vue {
  private screenList: Array<{name: string}> = []
  created (): void {
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.$axios.get(this.$apis.screen.selectScreenList,
      {}).then(res => {
      this.screenList = res || []
    })
  }

  openScreen (url: string) {
    window.open(url, '_blank')
  }
}
